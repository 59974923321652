import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import React from "react"
import { Fade } from "react-reveal"

import CtaButton from "../../primitives/CtaButton"
import { Arrow } from "../../primitives/Icons"

import { Graphic, Slide, Text, Wrapper } from "./Slides.styles"

const Slides = ({ activeIndex, slidesArr }) => (
  <Wrapper>
    {slidesArr.map((slide, i) => {
      return (
        i === activeIndex && (
          <Slide key={`slide-${i}`}>
            <Fade duration={1000} delay={200}>
              <Text>
                <h2>{slide.head}</h2>
                <p>{slide.sub}</p>
                {i === 1 ?
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '200px',
                    height: '20px',
                    backgroundColor: slide.color,
                    fontFamily: 'Arial, sans-serif',
                    textAlign: 'center',
                    borderRadius: '10px',
                    border: '2px solid',
                    padding: '12px 0',
                    color: 'white'
                  }}> 
                    <h1 style={{ fontSize: '20px', color: 'white' }}>Coming Soon!</h1>
                  </div>
                  :
                  <CtaButton
                    maxW="200px"
                    outLink={slide.outLink}
                    bgColor={slide.color}
                  >
                    Learn More
                    <Arrow style={{ paddingTop: "4px", paddingLeft: "12px" }} />
                  </CtaButton>
                }
              </Text>
            </Fade>
            <Graphic maxW={slide.width}>
              <Fade duration={1200} delay={200}>
                <GatsbyImage
                  image={slide.img}
                  style={{ maxWidth: slide.width, maxHeight: slide.height }}
                  alt=""
                />
              </Fade>
            </Graphic>
          </Slide>
        )
      )
    })}
  </Wrapper >
)

Slides.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  slidesArr: PropTypes.array.isRequired,
}

export default Slides
